
  import { Component, Mixins } from "vue-property-decorator";
  import mixinEmployeeEach from "@/mixins/mixinEmployeeEach";
  import header from "@/vuex/header";
  import FIcon from "@/components/f/Icon.vue";
  import LProgress from "@/components/l/Progress.vue";
  import EmployeeeachCard from "@/components/employeeeach/Card.vue";
  import * as util from "@/util";

  @Component({
    components: {
      FIcon,
      EmployeeeachCard,
      LProgress,
    },
  })
  export default class Spmenu extends Mixins(mixinEmployeeEach) {
    created() {
      this.m.initEditManager({ name: "card" });
    }

    get name() {
      const employee_name = this.header.employee_name.split(" ");
      return `${employee_name[0]} ${employee_name[1]}`;
    }

    logout() {
      header.setIsOpenLogoutDialog(true);
    }
    open_support_dialog() {
      header.setIsOpenSupportDialog(true);
    }

    async switch_branch_login() {
      window.location.href = "/switchlogin?auth=1";
    }
    async switch_company_login() {
      window.location.href = "/switchlogin?auth=2";
    }

    get is_app() {
      const app_token = util.getLocalStorage("app_token");
      return app_token == null ? 0 : 1;
    }
  }
