
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import * as util from "@/util";
  import EmployeeeachSelfBaseAuth from "@/components/employeeeach/self/base/Auth.vue";
  import EmployeeeachSelfBaseHiringtype from "@/components/employeeeach/self/base/Hiringtype.vue";
  import EmployeeeachSelfBaseMainbranch from "@/components/employeeeach/self/base/Mainbranch.vue";
  import EmployeeeachSelfBasePost from "@/components/employeeeach/self/base/Post.vue";
  import FControl from "@/components/f/Control.vue";
  import FButton from "@/components/f/Button.vue";
  import FImg from "@/components/f/Img.vue";

  @Component({
    components: {
      EmployeeeachSelfBaseAuth,
      EmployeeeachSelfBaseHiringtype,
      EmployeeeachSelfBaseMainbranch,
      EmployeeeachSelfBasePost,
      FControl,
      FButton,
      FImg,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() employee: any;
    @Prop() branch_hash: any;
    @Prop() global_config: any;
    @Prop() employeely_config: any;
    @Prop() prev_employee_id?: number;
    @Prop() next_employee_id?: number;

    startEditFaceImage() {
      this.edit({ employee: { face_blob: {}, ...this.raw_object } });
    }

    get raw_object() {
      return this.employee;
    }
    get tmp_object() {
      return this.manager.object.employee;
    }
    get object() {
      return this.manager.flag ? (this.tmp_object as any) : (this.raw_object as any);
    }

    changeEmployee(employee_id: number) {
      this.$emit("changeEmployee", employee_id);
    }

    startEditBase() {
      this.page = "self_base";
    }

    @Provide("is_edit")
    get is_edit() {
      return false;
    }

    @Provide("employee_type")
    get employee_type() {
      return this.employee.type;
    }

    registMypc() {
      this.$emit("registMypc");
    }

    get mypc_type() {
      if (this.employee.cookie && this.employee.cookie == util.getLocalStorage("mypc")) {
        return 2; //このPCで登録
      } else if (this.employee.cookie) {
        return 1; //他のPCで登録
      } else {
        return 0;
      }
    }
  }
