import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import vuex_table from '@/vuex/vuex_table';

@Component
export default class mixinEmployeeEach extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.employee_each_manager;
  }
  get global() {
    return vuex_data.employee_each_global.get(this);
  }
  get employeely() {
    return vuex_data.employee_each_employeely.get(this);
  }
  get base() {
    return vuex_data.employee_each_base.get(this);
  }
  get holiday() {
    return vuex_data.employee_each_holiday.get(this);
  }
  get info() {
    return vuex_data.employee_each_info.get(this);
  }
  get family() {
    return vuex_data.employee_each_family.get(this);
  }
  get belong() {
    return vuex_data.employee_each_belong.get(this);
  }
  get config() {
    return vuex_data.employee_each_config.get(this);
  }

  get paidhol_table() {
    return vuex_table.paidhol.get(this);
  }

  get employee_type_auth() {
    const type_array = [
      {shift: true, attendance: true, payment: true, nencho: true},
      {shift: true, attendance: false, payment: false, nencho: false},
      {shift: true, attendance: true, payment: false, nencho: false},
      {shift: false, attendance: false, payment: true, nencho: false},
      {shift: false, attendance: false, payment: true, nencho: true},
      {shift: false, attendance: false, payment: false, nencho: true},
    ];
    return type_array[this.employeely.employee.type];
  }

  get employee_id() {
    return this.s_auth == 0 ? this.s_employee_id : this.query.employee_id;
  }
  set employee_id(val) {
    if (val != this.employee_id) this.paidhol_table.page = 1;
    this.query = {employee_id: val};
  }
  get spouce_id() {
    return this.query.spouce_id;
  }
  set spouce_id(val) {
    this.query = {spouce_id: val};
  }
  get dependant_id() {
    return this.query.dependant_id;
  }
  set dependant_id(val) {
    this.query = {dependant_id: val};
  }

  get menu_options() {
    var options = {};

    if (this.global.config.showable_self) options['self'] = {name: '本人情報', route: 'self_general', pages: ['self_general', 'self_bank', 'self_tax', 'self_family', 'spouce', 'spouce_add', 'dependant', 'dependant_add', 'self_base']};
    if (this.global.config.showable_company || (this.global.config.showable_paidhol && this.employee_type_auth.attendance))  {
      if (this.global.config.showable_company) {
        options['labor'] = {name: '労務情報', route: 'labor_general', pages: ['labor_general', 'labor_payment', 'labor_taxins', 'labor_paidhol']};
      } else {
        options['labor'] = {name: '有給休暇', route: 'labor_paidhol', pages: ['labor_general', 'labor_payment', 'labor_taxins', 'labor_paidhol']};
      }
    }
    if (this.global.config.showable_self && this.s_auth != 0) if (this.employee_type_auth.shift || this.employee_type_auth.attendance) options['belong'] = {name: '所属', route: 'belong', pages: ['belong']};
    if (this.global.config.showable_self) options['info'] = {name: 'カスタム情報', route: 'info', pages: ['info']};
    if (this.global.config.showable_company && this.s_auth != 0) options['config'] = {name: '例外設定', route: 'config', pages: ['config']};
    
    return options;
  }

  get menu_sub_options() {
    var options = {};

    if (this.global.config.showable_self) {
      options['self'] = []
      options['self'].push({name: '全般', route: 'self_general', pages: ['self_general', 'self_base']});
      if (this.employee_type_auth.payment) options['self'].push({name: '振込口座', route: 'self_bank', pages: ['self_bank']});
      if (this.employee_type_auth.payment || this.employee_type_auth.nencho) options['self'].push({name: '税額表区分（甲乙）', route: 'self_tax', pages: ['self_tax']});
      if (this.employee_type_auth.payment || this.employee_type_auth.nencho) options['self'].push({name: '家族（扶養親族）', route: 'self_family', pages: ['self_family', 'spouce', 'spouce_add', 'dependant', 'dependant_add']});
    }

    if (this.global.config.showable_company || this.global.config.showable_paidhol) {
      options['labor'] = [];
      if (this.global.config.showable_company) options['labor'].push({name: '全般', route: 'labor_general', pages: ['labor_general']});
      if (this.global.config.showable_company) if (this.employee_type_auth.attendance || this.employee_type_auth.payment) if (this.s_auth != 0 || this.is_disp_personal_payment) options['labor'].push({name: '給与設定', route: 'labor_payment', pages: ['labor_payment']});
      if (this.global.config.showable_company) if (this.employee_type_auth.payment) options['labor'].push({name: '税・社会保険設定', route: 'labor_taxins', pages: ['labor_taxins']});
      if (this.global.config.showable_paidhol) if (this.employee_type_auth.attendance) options['labor'].push({name: '有給休暇', route: 'labor_paidhol', pages: ['labor_paidhol']});
    }
   
    if (this.global.config.showable_self && this.s_auth != 0) {
      if (this.employee_type_auth.shift || this.employee_type_auth.attendance) {
        options['belong'] = [];
        options['belong'].push({name: '所属', route: 'belong', pages: ['belong']});
      }
    }

    if (this.global.config.showable_self) {
      options['info'] = [];
      options['info'].push({name: 'カスタム情報', route: 'info', pages: ['info']});
    }

    if (this.global.config.showable_company && this.s_auth != 0) {
      options['config'] = [];
      options['config'].push({name: '例外設定', route: 'config', pages: ['config']});
    }
    
    return options;
  }
}
